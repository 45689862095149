define("frontend/mixins/cancelable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cancellation: (0, _model.belongsTo)('cancellation', {
      readOnly: true
    }),
    cancellationRequiresApproval: (0, _model.attr)('boolean')
  }); // import Mixin from '@ember/object/mixin';
  // import { belongsTo } from '@ember-decorators/data';
  //
  // export default class Cancelable extends Mixin {
  //   @belongsTo('cancellation') cancellation
  // }


  _exports.default = _default;
});