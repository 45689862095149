define("frontend/templates/admin/tutors/tutor/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xi/5K4Je",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.tutors.index\"]],[[\"default\"],[{\"statements\":[[2,\"Tutors\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.tutors.tutor.show\",[32,1]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"firstName\"]]]],\"parameters\":[]}]]],[2,\"\\n  »\\n  Edit\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/admin/tutors/tutor/edit.hbs"
    }
  });

  _exports.default = _default;
});