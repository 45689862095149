define("frontend/templates/admin/tutors/tutor/reject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VPuXORUM",
    "block": "{\"symbols\":[\"form\",\"@model\"],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.tutors.index\"]],[[\"default\"],[{\"statements\":[[2,\"Tutors\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.tutors.tutor.show\",[32,2,[\"tutorProfile\"]]]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"tutorProfile\",\"firstName\"]]]],\"parameters\":[]}]]],[2,\"\\n  »\\n  Reject\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,2,[\"tutorProfileEmployeeReview\"]],[30,[36,0],[[32,0],\"submit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\",\"@helpText\"],[\"textarea\",\"Rejection Notes\",\"rejectionNotes\",\"Visible to the tutor\"]],null],[2,\"\\n      \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\",\"@helpText\"],[\"textarea\",\"Internal Notes\",\"internalNotes\",\"Only visible to Tutorspot employees\"]],null],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary\"]],[[\"@route\"],[\"admin.tutors.index\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"bs-button\",[[24,4,\"submit\"]],[[\"@type\",\"@defaultText\"],[\"primary\",\"Reject\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/admin/tutors/tutor/reject.hbs"
    }
  });

  _exports.default = _default;
});