define("frontend/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MvPx6HGl",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Sign in\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container mt-3 mt-md-5 flex-grow-1 mb-5\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-4 col-xs-12\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/login.hbs"
    }
  });

  _exports.default = _default;
});