define("frontend/components/assignments/response-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsForm @model={{@changeset}} @onSubmit={{@onSubmit}} as |form|>
    <form.element @controlType="text-editor" @property="content" @label="Content" @showValidationOn="change" />
  
    <form.element @label="Attach Files">
      <FileUploaders::DropzoneUploader @onUpload={{@onFileUpload}} />
    </form.element>
  </BsForm>
  
  */
  {"id":"6k3vRBGk","block":"{\"symbols\":[\"form\",\"@changeset\",\"@onSubmit\",\"@onFileUpload\"],\"statements\":[[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\",\"@showValidationOn\"],[\"text-editor\",\"content\",\"Content\",\"change\"]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"element\"]],[],[[\"@label\"],[\"Attach Files\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"file-uploaders/dropzone-uploader\",[],[[\"@onUpload\"],[[32,4]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"frontend/components/assignments/response-form.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});