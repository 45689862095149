define("frontend/components/messages/messageables/tutor-availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="mt-2">
    {{#with (await @messageable.lineItems) as |lineItems|}}
      <div class="border p-1">
        {{#each (sort-by "fixedDate" lineItems) as |lineItem|}}
          <div class="mb-1">
            {{#if lineItem.fixedDate}}
              {{moment-format lineItem.date "LL"}}:
            {{else}}
              {{humanize (pluralize lineItem.dayOfWeek)}}:
            {{/if}}
            {{lineItem.timeStart}} - {{lineItem.timeEnd}}
          </div>
        {{/each}}
      </div>
    {{/with}}
  </div>
  
  */
  {"id":"uvZb+e6t","block":"{\"symbols\":[\"lineItems\",\"lineItem\",\"@messageable\"],\"statements\":[[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[32,3,[\"lineItems\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"border p-1\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[30,[36,4],[\"fixedDate\",[32,1]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"mb-1\"],[12],[2,\"\\n\"],[6,[37,3],[[32,2,[\"fixedDate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],[[32,2,[\"date\"]],\"LL\"],null]],[2,\":\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,1],[[30,[36,0],[[32,2,[\"dayOfWeek\"]]],null]],null]],[2,\":\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[1,[32,2,[\"timeStart\"]]],[2,\" - \"],[1,[32,2,[\"timeEnd\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pluralize\",\"humanize\",\"moment-format\",\"if\",\"sort-by\",\"-track-array\",\"each\",\"await\",\"with\"]}","meta":{"moduleName":"frontend/components/messages/messageables/tutor-availability.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});