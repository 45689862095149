define("frontend/templates/classroom/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qGCoZtSl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"iframe\"],[14,1,\"onlineClassroom\"],[14,0,\"online-classroom w-100\"],[14,\"frameborder\",\"0\"],[14,\"allow\",\"camera; microphone; display-capture; fullscreen\"],[15,\"src\",[32,0,[\"clientUrl\"]]],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/classroom/index.hbs"
    }
  });

  _exports.default = _default;
});