define("frontend/templates/classroom/post-lesson", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LAwF+iNa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container mt-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"h2\"],[12],[2,\"This lesson has ended!\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,0],[false],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"btn btn-primary btn-block\"],[24,\"target\",\"_top\"]],[[\"@route\"],[\"account.dashboard\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Complete Lesson Report\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary btn-block\"],[24,\"target\",\"_top\"]],[[\"@route\"],[\"account.dashboard\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Return to Dashboard\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "frontend/templates/classroom/post-lesson.hbs"
    }
  });

  _exports.default = _default;
});