define("frontend/mixins/alertable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accountAlerts: (0, _model.hasMany)('account-alert', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});