define("frontend/validations/rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { validatePresence, validateNumber } from 'ember-changeset-validations/validators';
  var _default = {// inPersonRate: [
    //   validatePresence(true),
    //   validateNumber({gt: 5})
    // ],
    // onlineRate: [
    //   validatePresence(true),
    //   validateNumber({gt: 5})
    // ]
  };
  _exports.default = _default;
});