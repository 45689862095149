define("frontend/components/levels/level-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route={{@route}} @model={{@routeModel}} ...attributes>
    <Levels::LevelLabel @model={{@model}} />
  </LinkTo>
  
  */
  {"id":"jcwVue0t","block":"{\"symbols\":[\"@route\",\"@routeModel\",\"&attrs\",\"@model\"],\"statements\":[[8,\"link-to\",[[17,3]],[[\"@route\",\"@model\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"levels/level-label\",[],[[\"@model\"],[[32,4]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"frontend/components/levels/level-link.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});