define("frontend/services/unified-event-handler", ["exports", "ember-singularity/services/unified-event-handler"], function (_exports, _unifiedEventHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _unifiedEventHandler.default;
    }
  });
});