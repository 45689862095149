define("frontend/templates/account/settings/lessons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rn+lznov",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,0,[\"changesetObject\"]],[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-none d-md-block mr-4\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"text-success h-100 my-auto\"]],[[\"@icon\",\"@size\"],[\"chalkboard\",\"2x\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"flex-grow-1\"],[12],[2,\"\\n      \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\",\"@helpText\"],[\"checkbox\",\"onlineWhiteboard\",\"Use Tutorspot Online Classroom\",\"Includes video & audio, chat, session recording and more.\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"changesetObject\",\"onlineWhiteboard\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"d-none d-md-block mr-4\"],[12],[2,\"\\n        \"],[8,\"fa-icon\",[[24,0,\"text-success h-100 my-auto\"]],[[\"@icon\",\"@size\"],[\"video\",\"2x\"]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"flex-grow-1\"],[12],[2,\"\\n        \"],[8,[32,1,[\"element\"]],[],[[\"@property\",\"@label\",\"@helpText\"],[\"conferenceUrl\",\"Video Calling Link\",\"e.g. Zoom\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary\"]],[[\"@route\"],[[32,0,[\"currentUser\",\"user\",\"homePath\"]]]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"bs-button\",[[24,4,\"submit\"]],[[\"@type\",\"@defaultText\",\"@pendingText\"],[\"primary\",\"Save\",\"Saving...\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/settings/lessons.hbs"
    }
  });

  _exports.default = _default;
});