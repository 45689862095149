define("frontend/components/lesson-reservations/schedule-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <dl>
    <dt>Schedule</dt>
    <dd>
      <LessonReservations::ScheduleLabel @schedule={{@model.schedule}} />
    </dd>
  
    <dt>Starting</dt>
    <dd>{{moment-format @model.firstLessonAt "ddd Do MMM YYYY"}}</dd>
  
    <dt>Ending</dt>
    <dd>{{moment-format @model.lastLessonAt "ddd Do MMM YYYY"}}</dd>
  
    <dt>Total Lessons</dt>
    <dd>{{@model.lessonsCount}}</dd>
  </dl>
  
  */
  {"id":"DaVWjpyQ","block":"{\"symbols\":[\"@model\"],\"statements\":[[10,\"dl\"],[12],[2,\"\\n  \"],[10,\"dt\"],[12],[2,\"Schedule\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[2,\"\\n    \"],[8,\"lesson-reservations/schedule-label\",[],[[\"@schedule\"],[[32,1,[\"schedule\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"dt\"],[12],[2,\"Starting\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[30,[36,0],[[32,1,[\"firstLessonAt\"]],\"ddd Do MMM YYYY\"],null]],[13],[2,\"\\n\\n  \"],[10,\"dt\"],[12],[2,\"Ending\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[30,[36,0],[[32,1,[\"lastLessonAt\"]],\"ddd Do MMM YYYY\"],null]],[13],[2,\"\\n\\n  \"],[10,\"dt\"],[12],[2,\"Total Lessons\"],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[32,1,[\"lessonsCount\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\"]}","meta":{"moduleName":"frontend/components/lesson-reservations/schedule-summary.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});