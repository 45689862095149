define("frontend/components/lesson-reservations/schedule-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{this.styleNamespace}} ...attributes>
    {{#each @schedule.days as |dayAttrs|}}
      <div>
        {{humanize dayAttrs.day}}s
        at
        {{dayAttrs.time}}
      </div>
    {{/each}}
  </div>
  
  */
  {"id":"kRBcIxaq","block":"{\"symbols\":[\"dayAttrs\",\"&attrs\",\"@schedule\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"styleNamespace\"]]],[17,2],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3,[\"days\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,1,[\"day\"]]],null]],[2,\"s\\n      at\\n      \"],[1,[32,1,[\"time\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"humanize\",\"-track-array\",\"each\"]}","meta":{"moduleName":"frontend/components/lesson-reservations/schedule-label.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});