define("frontend/templates/account/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1cwcIs23",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"Settings\"],[13],[2,\"\\n\\n\"],[8,\"section-switcher\",[[24,0,\"mb-4\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mb-2 mb-md-0 px-4\"]],[[\"@route\"],[\"account.settings.notifications\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Notification Preferences\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mb-2 mb-md-0 px-4\"]],[[\"@route\"],[\"account.settings.personal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Personal Details\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"currentUser\",\"user\",\"isTutor\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mb-2 mb-md-0 px-4\"]],[[\"@route\"],[\"account.settings.lessons\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Lesson Preferences\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/settings.hbs"
    }
  });

  _exports.default = _default;
});