define("frontend/templates/account/tutor/finance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qLHTf7rB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"Payments\"],[13],[2,\"\\n\\n\"],[8,\"section-switcher\",[[24,0,\"mb-4\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"d-inline-block\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mb-2 mb-md-0 px-4\"]],[[\"@route\",\"@disabled\"],[\"account.tutor.finance.payouts\",[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Payouts\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"bs-tooltip\",[],[[\"@title\"],[\"Complete your payment information first\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mb-2 mb-md-0 px-4\"]],[[\"@route\"],[\"account.tutor.finance.settings\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Settings\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disablePayoutsTab\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/finance.hbs"
    }
  });

  _exports.default = _default;
});