define("frontend/validations/assignment-response", ["exports", "ember-changeset-validations/validators", "ember-changeset-conditional-validations/validators/sometimes"], function (_exports, _validators, _sometimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {// content: validateSometimes([
    //   validatePresence(true)
    // ], function() {
    //   return isEmpty(this.get('fileUploads'))
    // })
  };
  _exports.default = _default;
});