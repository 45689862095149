define("frontend/mixins/progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    pageProgress: Ember.inject.service(),
    actions: {
      loading: function loading(transition) {
        var pageProgress = Ember.get(this, 'pageProgress');
        pageProgress.start(transition.targetName);
        transition.finally(function () {
          pageProgress.done();
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});