define("frontend/components/activity-feed/assignment-response-created-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ActivityFeed::ItemWrapper @icon="reply">
    <div>
      <strong>{{@eventable.clientProfile.firstNameAndLastInitial}}</strong>
      submitted a response to the assignment
      <strong>{{@eventable.assignment.title}}</strong>
    </div>
    <LinkTo @route="account.workspaces.workspace.assignments.assignment.responses.response.show" @model={{@eventable}}>
      View Response
    </LinkTo>
  </ActivityFeed::ItemWrapper>
  
  */
  {"id":"CSowzw9N","block":"{\"symbols\":[\"@eventable\"],\"statements\":[[8,\"activity-feed/item-wrapper\",[],[[\"@icon\"],[\"reply\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[32,1,[\"clientProfile\",\"firstNameAndLastInitial\"]]],[13],[2,\"\\n    submitted a response to the assignment\\n    \"],[10,\"strong\"],[12],[1,[32,1,[\"assignment\",\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"account.workspaces.workspace.assignments.assignment.responses.response.show\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    View Response\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"frontend/components/activity-feed/assignment-response-created-item.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});