define("frontend/templates/admin/tutors/tutor/edit/rates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OLilpIcV",
    "block": "{\"symbols\":[\"grid\",\"@model\"],\"statements\":[[8,\"rates/editable-grid\",[],[[\"@model\",\"@afterSave\",\"@columnar\"],[[32,2],[30,[36,0],[[32,0],\"afterSave\"],null],false]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[14,0,\"col-12 d-flex justify-content-end mt-3\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mr-1\"]],[[\"@route\"],[\"admin.tutors.tutor.show\"]],[[\"default\"],[{\"statements\":[[2,\"Back\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"bs-button\",[],[[\"@type\",\"@defaultText\",\"@pendingText\",\"@onClick\"],[\"primary\",\"Save\",\"Saving...\",[30,[36,0],[[32,0],[32,1,[\"onSave\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/admin/tutors/tutor/edit/rates.hbs"
    }
  });

  _exports.default = _default;
});