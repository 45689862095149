define("frontend/templates/admin/tutors/tutor/edit/verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gprOR6yt",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-6\"],[12],[2,\"\\n    \"],[8,\"bs-form/element\",[],[[\"@label\"],[\"Identification (front)\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"file-uploaders/verification-document-uploader\",[],[[\"@model\",\"@property\"],[[32,1],\"idFront\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row mb-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-6\"],[12],[2,\"\\n    \"],[8,\"bs-form/element\",[],[[\"@label\"],[\"Address Verification\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"file-uploaders/verification-document-uploader\",[],[[\"@model\",\"@property\"],[[32,1],\"addressVerification\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-6\"],[12],[2,\"\\n    \"],[8,\"bs-form/element\",[],[[\"@label\",\"@helpText\"],[\"DBS Certificate\",\"(Optional)\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"file-uploaders/verification-document-uploader\",[],[[\"@model\",\"@property\"],[[32,1],\"dbsCertificate\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mr-1\"]],[[\"@route\",\"@model\"],[\"admin.tutors.tutor.show\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"Back\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/admin/tutors/tutor/edit/verification.hbs"
    }
  });

  _exports.default = _default;
});