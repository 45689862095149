define("frontend/templates/account/tutor/lessons/lesson/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/mEyC46",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.tutor.lessons.index\"]],[[\"default\"],[{\"statements\":[[2,\"Lessons\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  Cancel\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[8,\"lessons/cancel-form\",[],[[\"@cancellation\",\"@lesson\",\"@errors\",\"@showClientName\",\"@onSubmit\",\"@backLink\"],[[32,1,[\"cancellation\"]],[32,1,[\"lesson\"]],[34,0],true,[30,[36,1],[[32,0],\"save\"],null],\"account.tutor.lessons.index\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"errors\",\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/lessons/lesson/cancel.hbs"
    }
  });

  _exports.default = _default;
});