define("frontend/templates/admin/conversations/conversation/messages/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "70KT5oNm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.conversations.index\"]],[[\"default\"],[{\"statements\":[[2,\"Conversations\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.conversations.conversation.show\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Conversation\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  Message\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/admin/conversations/conversation/messages/message.hbs"
    }
  });

  _exports.default = _default;
});