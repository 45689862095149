define("frontend/mixins/file-upload-context", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fileUploadLinks: (0, _model.hasMany)('file-upload-link', {
      polymorphic: true,
      inverse: 'context'
    }),
    fileUploads: (0, _model.hasMany)('file-upload', {
      readOnly: true,
      polymorphic: true,
      inverse: 'contexts'
    }),
    recentFileUploads: (0, _model.hasMany)('file-upload', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});