define("frontend/components/files/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
    <div class="d-flex">
      <div class="mr-1">
        <FaIcon @icon="file-alt" class="text-secondary" />
      </div>
      <div class="w-100">
        <a href={{@model.file.url}}>{{@model.name}}</a>
      </div>
    </div>
  </div>
  
  */
  {"id":"N893yaQC","block":"{\"symbols\":[\"&attrs\",\"@model\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mr-1\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"text-secondary\"]],[[\"@icon\"],[\"file-alt\"]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"w-100\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,6,[32,2,[\"file\",\"url\"]]],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"frontend/components/files/label.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});