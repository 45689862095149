define("frontend/templates/account/tutor/profile/rates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h5qQZUpq",
    "block": "{\"symbols\":[\"grid\",\"@model\"],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"rates/editable-grid\",[],[[\"@model\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-button\",[[16,\"disabled\",[30,[36,1],[[32,1,[\"enableSaveButton\"]]],null]]],[[\"@type\",\"@defaultText\",\"@pendingText\",\"@preventConcurrency\",\"@block\",\"@onClick\"],[\"primary\",\"Save\",\"Saving...\",true,true,[30,[36,0],[[32,0],[32,1,[\"onSave\"]]],null]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  Before you set rates,\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.tutor.profile.subjects\"]],[[\"default\"],[{\"statements\":[[2,\"choose which subjects you'll teach\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"not\",\"model\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/profile/rates.hbs"
    }
  });

  _exports.default = _default;
});