define("frontend/components/contact/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsForm @model={{@changesetObject}} @onSubmit={{@onSubmit}} as |form|>
    <div class="form-row">
      <div class="col-xs-12 col-md-6">
        <form.element @property="firstName" @label="First Name" @size="lg" />
      </div>
      <div class="col-xs-12 col-md-6">
        <form.element @property="lastName" @label="Last Name" @size="lg" />
      </div>
    </div>
  
    <form.element @controlType="email" @property="email" @label="Email" @size="lg" />
    <form.element @controlType="textarea" @property="message" @label="Message" @size="lg" />
  
    <div class="text-right">
      <BsButton @type="primary" @size="lg" type="submit" disabled={{@changesetObject.isInvalid}}>
        {{#if form.isSubmitting}}
          Sending
        {{else}}
          Send
        {{/if}}
      </BsButton>
    </div>
  </BsForm>
  
  */
  {"id":"YTKvjAJs","block":"{\"symbols\":[\"form\",\"@changesetObject\",\"@onSubmit\"],\"statements\":[[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-6\"],[12],[2,\"\\n      \"],[8,[32,1,[\"element\"]],[],[[\"@property\",\"@label\",\"@size\"],[\"firstName\",\"First Name\",\"lg\"]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-6\"],[12],[2,\"\\n      \"],[8,[32,1,[\"element\"]],[],[[\"@property\",\"@label\",\"@size\"],[\"lastName\",\"Last Name\",\"lg\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\",\"@size\"],[\"email\",\"email\",\"Email\",\"lg\"]],null],[2,\"\\n  \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\",\"@size\"],[\"textarea\",\"message\",\"Message\",\"lg\"]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[8,\"bs-button\",[[16,\"disabled\",[32,2,[\"isInvalid\"]]],[24,4,\"submit\"]],[[\"@type\",\"@size\"],[\"primary\",\"lg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"isSubmitting\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        Sending\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Send\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"frontend/components/contact/form.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});