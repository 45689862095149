define("frontend/validations/welcome/tutor/bio", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    tagLine: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 300
    })],
    biography: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});