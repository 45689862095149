define("frontend/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute('welcome.tutor.bio'), this.toRoute(['welcome.tutor.photo', 'welcome.tutor.location', 'welcome.tutor.experience', 'welcome.tutor.subjects', 'welcome.tutor.rates']), this.use('toLeft') // this.reverse('toRight')
    );
    this.transition(this.fromRoute('welcome.tutor.photo'), this.toRoute(['welcome.tutor.bio']), this.use('toRight') // this.reverse('toRight')
    );
    this.transition(this.fromRoute('welcome.tutor.photo'), this.toRoute(['welcome.tutor.location', 'welcome.tutor.experience', 'welcome.tutor.subjects', 'welcome.tutor.rates']), this.use('toLeft') // this.reverse('toRight')
    );
    this.transition(this.fromRoute('welcome.tutor.location'), this.toRoute(['welcome.tutor.bio', 'welcome.tutor.photo']), this.use('toRight') // this.reverse('toLeft')
    );
    this.transition(this.fromRoute('welcome.tutor.location'), this.toRoute(['welcome.tutor.experience', 'welcome.tutor.subjects', 'welcome.tutor.rates']), this.use('toLeft') // this.reverse('toLeft')
    );
    this.transition(this.fromRoute('welcome.tutor.experience'), this.toRoute(['welcome.tutor.bio', 'welcome.tutor.photo', 'welcome.tutor.location']), this.use('toRight') // this.reverse('toLeft')
    );
    this.transition(this.fromRoute('welcome.tutor.experience'), this.toRoute(['welcome.tutor.subjects', 'welcome.tutor.rates']), this.use('toLeft') // this.reverse('toLeft')
    );
    this.transition(this.fromRoute('welcome.tutor.subjects'), this.toRoute(['welcome.tutor.bio', 'welcome.tutor.photo', 'welcome.tutor.location', 'welcome.tutor.experience']), this.use('toRight') // this.reverse('toLeft')
    );
    this.transition(this.fromRoute('welcome.tutor.subjects'), this.toRoute(['welcome.tutor.rates']), this.use('toLeft') // this.reverse('toLeft')
    );
    this.transition(this.fromRoute('welcome.tutor.rates'), this.toRoute(['welcome.tutor.bio', 'welcome.tutor.photo', 'welcome.tutor.location', 'welcome.tutor.experience', 'welcome.tutor.subjects']), this.use('toRight') // this.reverse('toLeft')
    );
    this.transition(this.fromRoute('welcome.tutor.rates'), this.toRoute(['welcome.tutor.contact']), this.use('toLeft') // this.reverse('toLeft')
    );
    this.transition(this.fromRoute('welcome.tutor.contact'), this.toRoute(['welcome.tutor.bio', 'welcome.tutor.photo', 'welcome.tutor.location', 'welcome.tutor.experience', 'welcome.tutor.subjects', 'welcome.tutor.rates']), this.use('toRight') // this.reverse('toLeft')
    );
  }
});