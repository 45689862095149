define("frontend/templates/account/tutor/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bbhFYa38",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"mb-4 d-md-flex justify-content-between\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"Profile\"],[13],[2,\"\\n  \"],[8,\"marketing-link-to\",[[24,0,\"my-auto\"],[24,\"target\",\"_blank\"]],[[\"@route\"],[[31,[\"tutors/\",[32,1,[\"slug\"]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[[24,0,\"mr-1\"]],[[\"@icon\"],[\"eye\"]],null],[2,\"\\n    Preview\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"edit-tutor-profile/section-switcher\",[[24,0,\"mb-4\"]],[[],[]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/profile.hbs"
    }
  });

  _exports.default = _default;
});