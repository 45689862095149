define("frontend/templates/login/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7saiT18S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"Welcome Back!\"],[13],[2,\"\\n\\n\"],[8,\"login-form\",[],[[\"@onSubmit\"],[[30,[36,0],[[32,0],\"logIn\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/login/index.hbs"
    }
  });

  _exports.default = _default;
});