define("frontend/templates/account/tutor/lesson-reservations/lesson-reservation/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tfvUpuVe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.tutor.lesson-reservations.index\"]],[[\"default\"],[{\"statements\":[[2,\"Lesson Reservations\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  Cancel\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[8,\"lesson-reservations/cancel-form\",[],[[\"@cancellation\",\"@lessonReservation\",\"@backLink\",\"@onSubmit\"],[[34,0],[34,1],\"account.tutor.lesson-reservations.index\",[30,[36,2],[[32,0],\"submit\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"cancellation\",\"lessonReservation\",\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/lesson-reservations/lesson-reservation/cancel.hbs"
    }
  });

  _exports.default = _default;
});