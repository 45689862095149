define("frontend/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cz1d1sq5",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Not Found\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container mt-3 text-center\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"mb-3\"],[12],[2,\"Uh Oh!\"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"This page doesn't exist.\"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"Return to Homepage\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "meta": {
      "moduleName": "frontend/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});