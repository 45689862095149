define("frontend/validations/location", ["exports", "ember-changeset-validations/validators", "ember-changeset-conditional-validations/validators/sometimes"], function (_exports, _validators, _sometimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    formattedAddress: (0, _sometimes.default)((0, _validators.validatePresence)(true), function () {
      this.get('lessonsAtHome');
    })
  };
  _exports.default = _default;
});