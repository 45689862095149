define("frontend/templates/account/tutor/profile/subjects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9rUIOxiG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"mb-3\"],[12],[2,\"What Subjects Will You Tutor?\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-10\"],[12],[2,\"\\n    \"],[8,\"subjects/editable-grid\",[],[[\"@subjectCategories\",\"@subjectLevelsArray\"],[[32,0,[\"model\",\"subjectCategories\"]],[32,0,[\"subjectLevelsArray\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-2\"],[12],[2,\"\\n    \"],[8,\"bs-button\",[[16,\"disabled\",[30,[36,2],[[35,1]],null]]],[[\"@type\",\"@block\",\"@onClick\",\"@defaultText\",\"@pendingText\",\"@preventConcurrency\"],[\"primary\",true,[30,[36,0],[[32,0],\"save\"],null],\"Save\",\"Saving...\",true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"enableSaveButton\",\"not\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/profile/subjects.hbs"
    }
  });

  _exports.default = _default;
});