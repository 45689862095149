define("frontend/mixins/tutor-restricted-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var currentUser = this.currentUser.user;

      if (currentUser) {
        if (currentUser.isTutor) {
          return this._super.apply(this, arguments);
        } else {
          this.transitionTo(currentUser.homePath);
        }
      } else {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});