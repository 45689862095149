define("frontend/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QGiCoElb",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Account\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container-fluid mb-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-3 col-lg-2 col-xs-12 pt-md-3 side-nav-container\"],[12],[2,\"\\n      \"],[8,\"side-nav\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-md-9 col-lg-10 col-xs-12 pt-3 offset-lg-2\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/account.hbs"
    }
  });

  _exports.default = _default;
});