define("frontend/templates/welcome/tutor/photo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vxThkNn/",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[30,[36,0],[\"Profile Picture\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"p-1\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex mb-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mx-auto\"],[12],[2,\"\\n      \"],[8,\"edit-tutor-profile/upload-profile-picture\",[],[[\"@model\",\"@onUpload\",\"@onError\"],[[32,1],[30,[36,1],[[32,0],\"save\"],null],[30,[36,1],[[32,0],\"error\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"d-flex justify-content-between\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary\"]],[[\"@route\"],[\"welcome.tutor.bio\"]],[[\"default\"],[{\"statements\":[[2,\"Back\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@disabled\"],[\"welcome.tutor.location\",[30,[36,3],[[35,2,[\"profilePicture\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"Next\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"action\",\"model\",\"not\"]}",
    "meta": {
      "moduleName": "frontend/templates/welcome/tutor/photo.hbs"
    }
  });

  _exports.default = _default;
});