define("frontend/templates/account/conversations/conversation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nePzNZmZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"messages/conversation-container\",[],[[\"@conversation\",\"@messages\",\"@recipient\",\"@originatingTutorRequest\",\"@onSendMessage\"],[[32,0,[\"conversation\"]],[32,0,[\"messages\"]],[32,0,[\"recipient\"]],[32,0,[\"originatingTutorRequest\"]],[32,0,[\"sendMessage\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/account/conversations/conversation.hbs"
    }
  });

  _exports.default = _default;
});