define("frontend/validations/tutor-request-signed-in", ["exports", "frontend/validations/tutor-request-core"], function (_exports, _tutorRequestCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var signedInValidations = {};

  var _default = Ember.assign(_tutorRequestCore.default, signedInValidations);

  _exports.default = _default;
});