define("frontend/components/tables/sort-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @column.isAscSorted}}
    <FaIcon @icon="arrow-up" class="ml-2" />
  {{/if}}
  
  {{#if @column.isDescSorted}}
    <FaIcon @icon="arrow-down" class="ml-2" />
  {{/if}}
  
  */
  {"id":"by3qx4Lw","block":"{\"symbols\":[\"@column\"],\"statements\":[[6,[37,0],[[32,1,[\"isAscSorted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"fa-icon\",[[24,0,\"ml-2\"]],[[\"@icon\"],[\"arrow-up\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"isDescSorted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"fa-icon\",[[24,0,\"ml-2\"]],[[\"@icon\"],[\"arrow-down\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"frontend/components/tables/sort-indicator.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});