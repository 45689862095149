define("frontend/templates/account/tutor/client-profiles/client-profile/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eYg/lRUb",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.tutor.client-profiles.index\"]],[[\"default\"],[{\"statements\":[[2,\"Clients\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  \"],[1,[32,1,[\"fullName\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card mb-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[8,\"client-profiles/tutor-summary-panel\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n      \"],[8,\"lesson-reservations/table-for-tutor\",[],[[\"@model\",\"@showClientName\"],[[32,1,[\"lessonReservations\"]],false]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/client-profiles/client-profile/show.hbs"
    }
  });

  _exports.default = _default;
});