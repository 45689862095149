define("frontend/components/tables/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="text-center">
    <div class="mb-2">
      {{@table.paginationData.pageStart}} - {{@table.paginationData.pageEnd}} of {{@table.paginationData.totalRows}}
    </div>
  
    <BsButton @type="outline-secondary"
              @size="sm"
              @onClick={{@table.actions.previousPage}}
              disabled={{or @table.paginationData.isFirstPage @table.isLoading}}>
      <FaIcon @icon="arrow-left" />
    </BsButton>
  
    <BsButton @type="outline-secondary"
              @size="sm"
              @onClick={{@table.actions.nextPage}}
              disabled={{or @table.paginationData.isLastPage @table.isLoading}}>
      <FaIcon @icon="arrow-right" />
    </BsButton>
  </div>
  
  */
  {"id":"dLeLrfz8","block":"{\"symbols\":[\"@table\"],\"statements\":[[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-2\"],[12],[2,\"\\n    \"],[1,[32,1,[\"paginationData\",\"pageStart\"]]],[2,\" - \"],[1,[32,1,[\"paginationData\",\"pageEnd\"]]],[2,\" of \"],[1,[32,1,[\"paginationData\",\"totalRows\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"bs-button\",[[16,\"disabled\",[30,[36,0],[[32,1,[\"paginationData\",\"isFirstPage\"]],[32,1,[\"isLoading\"]]],null]]],[[\"@type\",\"@size\",\"@onClick\"],[\"outline-secondary\",\"sm\",[32,1,[\"actions\",\"previousPage\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"arrow-left\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"bs-button\",[[16,\"disabled\",[30,[36,0],[[32,1,[\"paginationData\",\"isLastPage\"]],[32,1,[\"isLoading\"]]],null]]],[[\"@type\",\"@size\",\"@onClick\"],[\"outline-secondary\",\"sm\",[32,1,[\"actions\",\"nextPage\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"arrow-right\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\"]}","meta":{"moduleName":"frontend/components/tables/pagination.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});