define("frontend/templates/account/tutor/profile/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TXAfXh14",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"mb-3\"],[12],[2,\"Set Your Location Preferences\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n    \"],[8,\"edit-tutor-profile/set-location\",[],[[\"@model\",\"@onSetLocation\",\"@onSetTravelDistance\"],[[32,0,[\"changesetObject\"]],[30,[36,0],[[32,0,[\"setLocation\"]]],null],[30,[36,0],[[32,0,[\"setTravelDistance\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-2\"],[12],[2,\"\\n    \"],[8,\"bs-button\",[[16,\"disabled\",[32,0,[\"changesetObject\",\"isPristine\"]]]],[[\"@type\",\"@defaultText\",\"@pendingText\",\"@block\",\"@onClick\"],[\"primary\",\"Save\",\"Saving...\",true,[30,[36,0],[[32,0,[\"save\"]],[32,0,[\"changesetObject\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/profile/location.hbs"
    }
  });

  _exports.default = _default;
});