define("frontend/templates/login/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V264m1sS",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h3\"],[12],[2,\"Set a New Password\"],[13],[2,\"\\n\\n\"],[8,\"auth/reset-password\",[],[[\"@model\",\"@onSubmit\"],[[32,1],[30,[36,0],[[32,0],\"submit\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/login/reset-password.hbs"
    }
  });

  _exports.default = _default;
});