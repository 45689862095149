define("frontend/mixins/eventable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    events: (0, _model.hasMany)('event', {
      polymorphic: true,
      inverse: 'eventable'
    })
  });

  _exports.default = _default;
});