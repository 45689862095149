define("frontend/templates/account/tutor/lessons/reports/complete-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3VpsON7a",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mb-4 d-md-flex justify-content-between\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"account.tutor.lessons.index\"]],[[\"default\"],[{\"statements\":[[2,\"Lessons\"]],\"parameters\":[]}]]],[2,\"\\n    »\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"account.tutor.lessons.reports.index\"]],[[\"default\"],[{\"statements\":[[2,\"Reports\"]],\"parameters\":[]}]]],[2,\"\\n    »\\n    Complete\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/lessons/reports/complete-all.hbs"
    }
  });

  _exports.default = _default;
});