define("frontend/templates/account/client/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WZbYV7AH",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[30,[36,0],[\"Dashboard\"],null]],[2,\"\\n\\n\"],[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"Dashboard\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row flex-wrap\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-lg-6 flex-grow-1 mb-3\"],[12],[2,\"\\n    \"],[8,\"dashboard/client-pending-lesson-reservations-widget\",[],[[\"@task\"],[[32,1,[\"pendingLessonReservationsTask\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-lg-6 flex-grow-1 mb-3\"],[12],[2,\"\\n    \"],[8,\"dashboard/client-upcoming-lessons-widget\",[],[[\"@task\"],[[32,1,[\"upcomingLessonsTask\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-lg-6 flex-grow-1 mb-3\"],[12],[2,\"\\n    \"],[8,\"dashboard/client-tutors-widget\",[],[[\"@task\"],[[32,1,[\"activeTutorsTask\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/client/dashboard.hbs"
    }
  });

  _exports.default = _default;
});