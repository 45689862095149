define("frontend/templates/account/settings/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q1t6I4y+",
    "block": "{\"symbols\":[\"form\",\"el\",\"el\",\"@model\"],\"statements\":[[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,4],[30,[36,0],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"element\"]],[],[[\"@label\"],[\"New Message Notifications\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\",\"@helpText\"],[\"checkbox\",\"newMessageEmail\",\"Email\",\"New message notifications will always be delivered by email\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"control\"]],[[16,\"disabled\",true]],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n    \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\",\"@helpText\"],[\"checkbox\",\"newMessageSms\",\"SMS\",[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"control\"]],[[16,\"disabled\",[34,2]]],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary\"]],[[\"@route\"],[[34,3,[\"user\",\"homePath\"]]]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"bs-button\",[[24,4,\"submit\"]],[[\"@type\",\"@defaultText\",\"@pendingText\"],[\"primary\",\"Save\",\"Saving...\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"smsNotificationsHelpText\",\"disableSmsNotificationsCheckbox\",\"currentUser\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/settings/notifications.hbs"
    }
  });

  _exports.default = _default;
});