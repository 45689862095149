define("frontend/templates/account/workspaces/workspace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "phrZwi6X",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.workspaces\"]],[[\"default\"],[{\"statements\":[[2,\"Workspaces\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"section-switcher\",[[24,0,\"mb-4\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mb-2 mb-md-0 px-4\"]],[[\"@route\"],[\"account.workspaces.workspace.show\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Home\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mb-2 mb-md-0 px-4\"]],[[\"@route\"],[\"account.workspaces.workspace.files\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Files\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mb-2 mb-md-0 px-4\"]],[[\"@route\"],[\"account.workspaces.workspace.assignments\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Assignments\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/workspaces/workspace.hbs"
    }
  });

  _exports.default = _default;
});