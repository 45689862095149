define("frontend/templates/admin/conversations/conversation/messages/message/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P48XSEJ1",
    "block": "{\"symbols\":[\"form\",\"el\",\"el\"],\"statements\":[[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,0,[\"changesetObject\"]],[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-6\"],[12],[2,\"\\n      \"],[8,[32,1,[\"element\"]],[],[[\"@property\",\"@label\"],[\"sender.fullName\",\"Sender\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"p\"],[14,0,\"form-control-plaintext\"],[12],[2,\"\\n          \"],[1,[32,3,[\"value\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-6\"],[12],[2,\"\\n      \"],[8,[32,1,[\"element\"]],[],[[\"@property\",\"@label\"],[\"recipient.fullName\",\"Recipient\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"p\"],[14,0,\"form-control-plaintext\"],[12],[2,\"\\n          \"],[1,[32,2,[\"value\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"element\"]],[],[[\"@property\",\"@controlType\",\"@label\"],[\"body\",\"textarea\",\"Message\"]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary\"]],[[\"@route\"],[\"admin.conversations.conversation.show\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Cancel\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"bs-button\",[[24,4,\"submit\"]],[[\"@type\",\"@defaultText\",\"@pendingText\"],[\"primary\",\"Save\",\"Saving...\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/admin/conversations/conversation/messages/message/edit.hbs"
    }
  });

  _exports.default = _default;
});