define("frontend/validations/sign-up", ["exports", "frontend/validations/user-core", "ember-changeset-validations/validators"], function (_exports, _userCore, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var signUpValidations = {
    password: (0, _validators.validatePresence)(true),
    termsAcceptance: (0, _validators.validatePresence)(true)
  };

  var _default = Ember.assign(_userCore.default, signUpValidations);

  _exports.default = _default;
});