define("frontend/validations/page", ["exports", "ember-changeset-validations/validators", "ember-changeset-conditional-validations/validators/sometimes"], function (_exports, _validators, _sometimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var columnFieldConditional = function columnFieldConditional(changes) {
    if (changes.hasOwnProperty('showColumns')) {
      return changes.showColumns;
    } else {
      return this.get('showColumns');
    }
  };

  var _default = {
    slug: (0, _validators.validatePresence)(true),
    pageTitle: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 48
    })],
    metaDescription: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 160
    })],
    header1: (0, _validators.validatePresence)(true) // @todo: Why is this not working on edit?
    // column1Title: validateSometimes(validatePresence(true), columnFieldConditional),
    // column1Text: validateSometimes(validatePresence(true), columnFieldConditional),
    // column2Title: validateSometimes(validatePresence(true), columnFieldConditional),
    // column2Text: validateSometimes(validatePresence(true), columnFieldConditional),
    // column3Title: validateSometimes(validatePresence(true), columnFieldConditional),
    // column3Text: validateSometimes(validatePresence(true), columnFieldConditional),

  };
  _exports.default = _default;
});