define("frontend/templates/account/tutor/profile/qualifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1SZ4344W",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h3\"],[14,0,\"mb-3\"],[12],[2,\"What Qualifications Do You Have?\"],[13],[2,\"\\n\\n\"],[8,\"qualifications/editable-list\",[],[[\"@changesets\",\"@changesetsToDelete\",\"@levels\"],[[34,0],[34,1],[32,1,[\"levels\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"changesets\",\"changesetsToDelete\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/profile/qualifications.hbs"
    }
  });

  _exports.default = _default;
});