define("frontend/validations/tutor-request-core", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // level: validatePresence(true),
    subject: (0, _validators.validatePresence)(true),
    lessonType: (0, _validators.validatePresence)(true),
    message: (0, _validators.validatePresence)(true) // formattedAddress: validatePresence(true) // Should really require this then lessonType === 'in_person'

  };
  _exports.default = _default;
});