define("frontend/validations/edit-user", ["exports", "ember-changeset-validations/validators", "frontend/validations/user-core", "frontend/validations/user-address"], function (_exports, _validators, _userCore, _userAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var editUserValidations = {
    passwordConfirmation: (0, _validators.validateConfirmation)({
      on: 'password'
    })
  };

  var _default = Ember.assign(_userCore.default, _userAddress.default, editUserValidations);

  _exports.default = _default;
});