define("frontend/templates/welcome/tutor/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QUJEM5YG",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Location\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"p-1\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"mb-3\"],[12],[2,\"Set Your Location\"],[13],[2,\"\\n\\n  \"],[8,\"edit-tutor-profile/set-location\",[[24,0,\"mb-3\"]],[[\"@model\",\"@onSetLocation\",\"@onSetTravelDistance\"],[[32,0,[\"changesetObject\"]],[32,0,[\"setLocation\"]],[32,0,[\"setTravelDistance\"]]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"d-flex justify-content-between mt-3\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary\"]],[[\"@route\"],[\"welcome.tutor.rates\"]],[[\"default\"],[{\"statements\":[[2,\"Back\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"bs-button\",[],[[\"@type\",\"@defaultText\",\"@pendingText\",\"@onClick\"],[\"primary\",\"Next\",\"Saving...\",[30,[36,1],[[32,0,[\"save\"]],[32,0,[\"changesetObject\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"fn\"]}",
    "meta": {
      "moduleName": "frontend/templates/welcome/tutor/location.hbs"
    }
  });

  _exports.default = _default;
});