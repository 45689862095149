define("frontend/templates/account/client/lesson-reservations/lesson-reservation/accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o9cpxOly",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.client.lesson-reservations.index\"]],[[\"default\"],[{\"statements\":[[2,\"Tutors\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  Accept\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[8,\"lesson-reservations/acceptance-container\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/account/client/lesson-reservations/lesson-reservation/accept.hbs"
    }
  });

  _exports.default = _default;
});