define("frontend/templates/account/workspaces/workspace/assignments/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T2dv1uKn",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[\"create assignment\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"d-md-flex justify-content-end mb-3\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"account.workspaces.workspace.assignments.all.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          New Assignment\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"assignments/list\",[],[[\"@workspace\"],[[32,1,[\"workspace\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"can\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/workspaces/workspace/assignments/all.hbs"
    }
  });

  _exports.default = _default;
});