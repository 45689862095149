define("frontend/templates/account/tutor/profile/availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b4C1hpnq",
    "block": "{\"symbols\":[\"changesetObject\",\"form\",\"@model\"],\"statements\":[[10,\"h3\"],[14,0,\"mb-3\"],[12],[2,\"Set Your Availability\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,1],[30,[36,0],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n        \"],[8,[32,2,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\"],[\"checkbox\",\"availableWeekdayDays\",\"Weekday Days\"]],null],[2,\"\\n        \"],[8,[32,2,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\"],[\"checkbox\",\"availableWeekdayEvenings\",\"Weekday Evenings\"]],null],[2,\"\\n        \"],[8,[32,2,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\"],[\"checkbox\",\"availableWeekends\",\"Weekends\"]],null],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-2\"],[12],[2,\"\\n        \"],[8,\"bs-button\",[[16,\"disabled\",[32,1,[\"isPristine\"]]],[24,4,\"submit\"]],[[\"@type\",\"@defaultText\",\"@block\"],[\"primary\",\"Save\",true]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"changeset\",\"with\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/profile/availability.hbs"
    }
  });

  _exports.default = _default;
});