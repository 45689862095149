define("frontend/templates/legal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+YmlyJWB",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Policies\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container mt-3 mb-3 flex-grow-1\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-3\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary\"]],[[\"@route\"],[\"legal.terms\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Terms & Conditions\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary\"]],[[\"@route\"],[\"legal.privacy\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Privacy & Cookies Policy\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/legal.hbs"
    }
  });

  _exports.default = _default;
});