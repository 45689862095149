define("frontend/templates/account/settings/personal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FqLC6lZV",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-9\"],[12],[2,\"\\n    \"],[8,\"users/edit-form\",[],[[\"@model\",\"@onSave\"],[[32,1],[30,[36,0],[[32,0],\"save\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/settings/personal.hbs"
    }
  });

  _exports.default = _default;
});