define("frontend/utils/apply-model-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(changeset) {
    var model = changeset.data;
    var modelErrors = model.errors;
    var result;

    if (Ember.isPresent(modelErrors)) {
      modelErrors.forEach(function (_ref) {
        var attribute = _ref.attribute,
            message = _ref.message;
        changeset.pushErrors(attribute, message);
      });
      result = true;
    } else {
      result = false;
    }

    return result;
  }
});