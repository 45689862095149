define("frontend/validations/lesson-reservation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    clientProfile: (0, _validators.validatePresence)(true),
    subject: (0, _validators.validatePresence)(true),
    level: (0, _validators.validatePresence)(true),
    schedule: (0, _validators.validatePresence)(true),
    // 'schedule.days': validatePresence(true),       // @todo: Fix this
    // 'schedule.frequency': validatePresence(true),
    // 'schedule.starts': validatePresence(true),
    // 'schedule.ends': validatePresence(true),
    duration: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      gte: 0.5,
      lte: 720,
      message: function message(key, type) {
        switch (type) {
          case 'notANumber':
            return 'Duration must be a number';

          case 'positive':
            return 'Duration must be a positive number';

          case 'lessThanOrEqualTo':
            return 'Lesson cannot be longer than 12 hours';

          case 'greaterThanOrEqualTo':
            return 'Lesson must be longer than 30 minutes';
        }
      }
    })],
    rate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gt: 0
    })]
  };
  _exports.default = _default;
});