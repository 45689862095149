define("frontend/templates/admin/tutors/tutor/edit/search-ranking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g+WkaHXN",
    "block": "{\"symbols\":[\"form\",\"@model\"],\"statements\":[[10,\"h3\"],[12],[2,\"Search Ranking Flags\"],[13],[2,\"\\n\\n\"],[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,2],[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\"],[\"checkbox\",\"available\",\"Available\"]],null],[2,\"\\n  \"],[8,[32,1,[\"element\"]],[],[[\"@controlType\",\"@property\",\"@label\"],[\"checkbox\",\"tutorspotRecommended\",\"Tutorspot Recommended\"]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-outline-secondary mr-1\"]],[[\"@route\"],[\"admin.tutors.tutor.show\"]],[[\"default\"],[{\"statements\":[[2,\"Back\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"bs-button\",[[24,4,\"submit\"]],[[\"@type\",\"@defaultText\",\"@pendingText\"],[\"primary\",\"Save\",\"Saving...\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/admin/tutors/tutor/edit/search-ranking.hbs"
    }
  });

  _exports.default = _default;
});