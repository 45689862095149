define("frontend/templates/admin/users/user/edit/set-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NkwSSh7i",
    "block": "{\"symbols\":[\"Modal\",\"form\",\"@model\"],\"statements\":[[8,\"bs-modal\",[],[[\"@backdropClose\",\"@keyboard\",\"@onHidden\"],[false,false,[32,0,[\"transitionBack\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[2,\"\\n      Set Password\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,3],[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"element\"]],[],[[\"@property\",\"@label\"],[\"password\",\"New Password\"]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\",\"@defaultText\"],[[32,1,[\"close\"]],\"outline-secondary\",\"Cancel\"]],null],[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\",\"@defaultText\"],[[32,1,[\"submit\"]],\"primary\",\"Save\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/admin/users/user/edit/set-password.hbs"
    }
  });

  _exports.default = _default;
});