define("frontend/templates/account/tutor/finance/payouts/payout/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XDYZhXY1",
    "block": "{\"symbols\":[\"modal\",\"@model\"],\"statements\":[[8,\"bs-modal\",[],[[\"@size\",\"@onHidden\"],[\"xl\",[30,[36,0],[[32,0],\"transitionToIndex\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[2,\"Lessons in Payout\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"stripe-connect-payouts/tutor-line-item-breakdown\",[],[[\"@model\"],[[32,2]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/finance/payouts/payout/show.hbs"
    }
  });

  _exports.default = _default;
});