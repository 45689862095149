define("frontend/templates/account/workspaces/workspace/files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BZZCJkh9",
    "block": "{\"symbols\":[\"fileUpload\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-md-flex justify-content-end mb-3\"],[12],[2,\"\\n      \"],[8,\"file-uploaders/workspace-file-uploader\",[],[[\"@workspace\"],[[32,2,[\"workspace\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[3,\"    <Files::Table @linkedTo=\\\"Workspace|{{@model.workspace.id}}\\\" />\"],[2,\"\\n\\n    \"],[10,\"table\"],[14,0,\"table\"],[12],[2,\"\\n      \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"Created\"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"Uploaded By\"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2,[\"files\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n              \"],[1,[30,[36,0],[[32,1,[\"createdAt\"]]],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"td\"],[12],[2,\"\\n              \"],[1,[32,1,[\"name\"]]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"td\"],[12],[2,\"\\n              \"],[1,[32,1,[\"owner\",\"firstNameAndLastInitial\"]]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"td\"],[12],[2,\"\\n              \"],[10,\"a\"],[15,6,[32,1,[\"file\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"Download\"],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-from-now\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/workspaces/workspace/files.hbs"
    }
  });

  _exports.default = _default;
});