define("frontend/templates/account/workspaces/workspace/assignments/assignment/responses/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8/SnPdVV",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[8,\"bs-modal\",[[24,0,\"right\"]],[[\"@backdropClose\",\"@keyboard\",\"@onHidden\"],[false,false,[32,0,[\"transitionBack\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[2,\"\\n      Submit Assignment Response\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"assignments/response-form\",[],[[\"@changeset\",\"@onSubmit\",\"@onFileUpload\"],[[32,0,[\"changesetObject\"]],[32,0,[\"save\"]],[32,0,[\"onUpload\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\",\"@defaultText\"],[[32,1,[\"close\"]],\"outline-secondary\",\"Cancel\"]],null],[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\",\"@defaultText\"],[[32,1,[\"submit\"]],\"primary\",\"Save\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/account/workspaces/workspace/assignments/assignment/responses/new.hbs"
    }
  });

  _exports.default = _default;
});