define("frontend/templates/account/workspaces/workspace/assignments/assignment/responses/response/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ocRYz/FV",
    "block": "{\"symbols\":[\"Modal\",\"fileUpload\",\"@model\"],\"statements\":[[8,\"bs-modal\",[[24,0,\"right\"]],[[\"@backdropClose\",\"@keyboard\",\"@onHidden\"],[false,false,[32,0,[\"transitionBack\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"mb-0 small text-black-50\"],[12],[2,\"Assignment\"],[13],[2,\"\\n      \"],[10,\"h4\"],[14,0,\"modal-title mb-0\"],[12],[2,\"\\n        \"],[1,[32,3,[\"assignment\",\"title\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"mb-3\"],[12],[2,\"\\n      \"],[10,\"h5\"],[12],[2,\"Content\"],[13],[2,\"\\n      \"],[1,[30,[36,0],[[32,3,[\"content\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mb-3\"],[12],[2,\"\\n      \"],[10,\"h5\"],[12],[2,\"Files\"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3,[\"fileUploads\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"mb-2\"],[12],[2,\"\\n          \"],[8,\"files/label\",[[24,0,\"mr-2\"]],[[\"@model\"],[[32,2]]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[2,\"        \"],[10,\"em\"],[12],[2,\"No files were uploaded with this response.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bs-button\",[],[[\"@onClick\",\"@type\",\"@defaultText\"],[[32,1,[\"close\"]],\"outline-secondary\",\"Close\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/workspaces/workspace/assignments/assignment/responses/response/show.hbs"
    }
  });

  _exports.default = _default;
});