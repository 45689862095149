define("frontend/initializers/current-user-injector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject("ability", "currentUser", "service:current-user");
    application.inject("route", "currentUser", "service:current-user");
    application.inject("component", "currentUser", "service:current-user");
    application.inject("controller", "currentUser", "service:current-user");
  }

  var _default = {
    name: 'current-user-injector',
    initialize: initialize
  };
  _exports.default = _default;
});