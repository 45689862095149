define("frontend/templates/account/tutor/lesson-reservations/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8iEfI8S8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"Create Lesson Reservation\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[8,\"lesson-reservations/new-container\",[],[[\"@lessonReservation\",\"@connectAccount\",\"@onSave\",\"@onSaveStripeAccount\"],[[32,0,[\"lessonReservation\"]],[32,0,[\"connectAccount\"]],[32,0,[\"save\"]],[32,0,[\"saveStripeAccount\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/lesson-reservations/new.hbs"
    }
  });

  _exports.default = _default;
});