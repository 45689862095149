define("frontend/components/activity-feed/item-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="d-flex mb-5">
    <div class="mr-4">
      <div class="rounded-circle p-2 mt-1 bg-primary">
        <FaIcon @icon={{@icon}} @size="2x" class="text-white" />
      </div>
    </div>
  
    <div class="w-100 my-auto">
      {{yield}}
    </div>
  </div>
  
  */
  {"id":"zxaYSHf1","block":"{\"symbols\":[\"@icon\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex mb-5\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mr-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"rounded-circle p-2 mt-1 bg-primary\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"text-white\"]],[[\"@icon\",\"@size\"],[[32,1],\"2x\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"w-100 my-auto\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"frontend/components/activity-feed/item-wrapper.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});