define("frontend/templates/account/tutor/references/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "61vmgc5v",
    "block": "{\"symbols\":[\"Modal\",\"@model\"],\"statements\":[[8,\"bs-modal\",[],[[\"@onHide\"],[[30,[36,0],[[32,0],\"cancel\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[2,\"\\n      Add Reference\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tutor-references/form\",[],[[\"@model\",\"@onSubmit\",\"@onCancel\"],[[30,[36,2],[[32,2],[35,1]],null],[30,[36,0],[[32,0],\"submit\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"ReferenceValidations\",\"changeset\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/references/new.hbs"
    }
  });

  _exports.default = _default;
});