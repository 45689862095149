define("frontend/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zMzuXxhZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"head-layout\",[],[[],[]],null],[2,\"\\n\"],[1,[30,[36,2],[\"Tutorspot\"],null]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"isWhiteboard\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"top-navbar\",[],[[\"@onLogIn\",\"@onLogOut\"],[[32,0,[\"makeLoginModalVisible\"]],[32,0,[\"logOut\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"showLoginModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"login-modal\",[],[[\"@onHidden\",\"@logIn\"],[[30,[36,1],[[30,[36,0],[[32,0,[\"showLoginModal\"]]],null],false],null],[32,0,[\"logIn\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"ember-notify\",[],[[\"@closeAfter\",\"@messageStyle\",\"@defaultClass\"],[4000,\"bootstrap\",\"app-notification\"]],null],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"application-container d-flex flex-column flex-grow-1 \",[30,[36,4],[[32,0,[\"isWhiteboard\"]],\"whiteboard-container\"],null]]]],[12],[2,\"\\n  \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"page-progress\",[],[[\"@background\"],[\"white\"]],null],[2,\"\\n\\n\"],[8,\"intercom-io\",[],[[],[]],null],[2,\"\\n\\n\"],[8,\"ember-load-remover\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"page-title\",\"unless\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/application.hbs"
    }
  });

  _exports.default = _default;
});