define("frontend/templates/account/workspaces/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zyAYXgjY",
    "block": "{\"symbols\":[\"workspace\",\"@model\"],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"account.workspaces\"]],[[\"default\"],[{\"statements\":[[2,\"Workspaces\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  All\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row mb-3\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-xs-12 col-md-4 mb-3\"],[12],[2,\"\\n      \"],[8,\"workspaces/summary-card\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"includeDeactivated\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@query\"],[[30,[36,0],null,[[\"includeDeactivated\"],[false]]]]],[[\"default\"],[{\"statements\":[[2,\"Hide Deactivated\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@query\"],[[30,[36,0],null,[[\"includeDeactivated\"],[true]]]]],[[\"default\"],[{\"statements\":[[2,\"Show Deactivated\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/workspaces/all.hbs"
    }
  });

  _exports.default = _default;
});