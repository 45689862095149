define("frontend/validations/welcome/tutor/contact", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    homePhone: (0, _validators.validatePresence)(true),
    mobilePhone: (0, _validators.validatePresence)(true),
    addressLine1: (0, _validators.validatePresence)(true),
    addressCity: (0, _validators.validatePresence)(true),
    addressState: (0, _validators.validatePresence)(true),
    addressPostalCode: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});