define("frontend/validations/client-sign-up", ["exports", "frontend/validations/sign-up", "ember-changeset-validations/validators"], function (_exports, _signUp, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var signUpValidations = {
    mobilePhone: (0, _validators.validatePresence)(true)
  };

  var _default = Ember.assign(_signUp.default, signUpValidations);

  _exports.default = _default;
});