define("frontend/validations/stripe-connect-account", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    dobDay: (0, _validators.validatePresence)(true),
    dobMonth: (0, _validators.validatePresence)(true),
    dobYear: (0, _validators.validatePresence)(true),
    // routingNumber: validatePresence(true),
    // accountNumber: validatePresence(true),
    tosAcceptanceDate: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});