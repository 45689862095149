define("frontend/validations/admin/edit-lesson-reservation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    duration: (0, _validators.validatePresence)(true),
    rate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gt: 0
    })]
  };
  _exports.default = _default;
});