define("frontend/templates/welcome/tutor/bio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RruSzlAc",
    "block": "{\"symbols\":[\"changesetObject\",\"form\",\"el\",\"el\",\"@model\"],\"statements\":[[1,[30,[36,1],[\"Bio\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"p-1\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,5],[35,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[32,1],[30,[36,0],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Tell Students About Yourself\"],[13],[2,\"\\n\\n      \"],[8,[32,2,[\"element\"]],[],[[\"@property\",\"@label\"],[\"tagLine\",\"Tag Line\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,4,[\"control\"]],[[24,\"placeholder\",\"Introduce yourself in a sentence or two\"]],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[4]}]]],[2,\"\\n\\n      \"],[8,[32,2,[\"element\"]],[],[[\"@property\",\"@label\",\"@controlType\"],[\"biography\",\"Bio\",\"textArea\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"textarea\",[[24,\"placeholder\",\"Explain your experience and teaching style. This is your opportunity to sell yourself to potential students.\"],[24,0,\"form-control\"]],[[\"@autoresize\",\"@rows\",\"@max-rows\",\"@value\",\"@id\"],[true,3,8,[32,3,[\"value\"]],[32,3,[\"id\"]]]],null],[2,\"      \"]],\"parameters\":[3]}]]],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-right mt-3\"],[12],[2,\"\\n        \"],[8,\"bs-button\",[[16,\"disabled\",[32,1,[\"isInvalid\"]]],[24,4,\"submit\"]],[[\"@type\"],[\"primary\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Next\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"page-title\",\"TutorBioValidations\",\"changeset\",\"with\"]}",
    "meta": {
      "moduleName": "frontend/templates/welcome/tutor/bio.hbs"
    }
  });

  _exports.default = _default;
});