define("frontend/mixins/route-setup-controller-changeset", ["exports", "ember-changeset", "ember-changeset-validations"], function (_exports, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    validationsPropertyName: 'Validations',
    nestedModelPath: null,
    setupController: function setupController(controller, modelObject) {
      this._super.apply(this, arguments);

      var changeset;
      var validations = controller.get(this.validationsPropertyName);
      var model = this.nestedModelPath ? modelObject[this.nestedModelPath] : modelObject;

      if (validations) {
        changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validations), validations);
      } else {
        changeset = new _emberChangeset.default(model);
      }

      controller.set('changesetObject', changeset);
    }
  });

  _exports.default = _default;
});