define("frontend/templates/admin/pages/page/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6hXy5ZB7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"mb-4\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"admin.pages.index\"]],[[\"default\"],[{\"statements\":[[2,\"Pages\"]],\"parameters\":[]}]]],[2,\"\\n  »\\n  Edit\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n    \"],[8,\"pages/form\",[],[[\"@changeset\",\"@onSubmit\"],[[32,0,[\"changesetObject\"]],[32,0,[\"save\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/admin/pages/page/edit.hbs"
    }
  });

  _exports.default = _default;
});