define("frontend/instance-initializers/ember-cli-airbrake", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function registerEmberOnError(notifyFn) {
    var originalOnError = Ember.onerror || function () {};

    Ember.onerror = function (err) {
      originalOnError(err);
      notifyFn(err);
    };
  }

  function registerWindowOnError(notifyFn) {
    window.onerror = function (message, file, line, column, error) {
      if (message === 'Script error.') {
        // Ignore.
        return;
      }

      error = error || {
        error: {
          message: message,
          fileName: file,
          lineNumber: line,
          columnNumber: column || 0
        }
      };
      notifyFn(error);
    };
  }

  function initialize(instance) {
    // see http://emberjs.com/deprecations/v2.x/#toc_ember-applicationinstance-container
    var lookup = instance.lookup ? function () {
      return instance.lookup.apply(instance, arguments);
    } : function () {
      var _instance$container;

      return (_instance$container = instance.container).lookup.apply(_instance$container, arguments);
    };

    var notifyFn = function notifyFn(error) {
      var airbrake = lookup('service:airbrake');
      airbrake.notify(error);
    };

    if (_environment.default.airbrake) {
      registerEmberOnError(notifyFn);
      registerWindowOnError(notifyFn);
    }
  }

  var _default = {
    name: 'ember-cli-airbrake',
    initialize: initialize
  };
  _exports.default = _default;
});