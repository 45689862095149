define("frontend/templates/account/tutor/lesson-reservations/lesson-reservation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NgUKkaj/",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/templates/account/tutor/lesson-reservations/lesson-reservation.hbs"
    }
  });

  _exports.default = _default;
});